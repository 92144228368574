<template>
      <div class="pages" v-loading="loading">
        <table-height-auto>
          <template slot="before">
            <el-form :inline="true" class="demo-form-inline">
              <el-form-item>
                <el-select v-model="searchData.searchType"
                           placeholder="请选择关键字类型"
                           style="width: 120px">
                  <el-option
                    v-for="item in searchKeyType"
                    @change="search"
                    :disabled="item.disabled"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item>
                <el-input
                  class
                  placeholder="搜索关键字"
                  prefix-icon="el-icon-search"
                  v-model="searchData.searchValue"
                  @keyup.enter.native="search"
                  style="width: 260px"
                >
                  <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
                </el-input>
              </el-form-item>

              <el-form-item label="结算状态:">
                <el-select v-model="searchData.status" placeholder="结算状态" style="width: 120px" @change="search">
                  <el-option
                    v-for="item in statusArray"
                    :disabled="item.disabled"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>

                <el-form-item>
                  <el-select v-model="searchData.timeType" placeholder="时间类型" style="width: 120px">
                    <el-option
                      v-for="item in timeTypeArray"
                      :disabled="item.disabled"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item >
                  <el-date-picker
                    format="yyyy-MM-dd HH:mm:ss"
                    v-model="searchData.selectTime"
                    :clearable="false"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>

                <el-form-item>
                   <el-button type="primary" @click="search">查询</el-button>
                  <el-button @click="downloadExcel" icon="el-icon-download">导出</el-button>
                </el-form-item>
            </el-form>

             <div style="text-align: center;color: red; ">
               <span style="padding-right: 35px;" v-if="showStartEndTime"> {{showStartEndTime[0]}}至{{showStartEndTime[1]}} </span>
               <span style="padding-right: 35px;">已结算金额：{{searchData.settledAmount}}元</span>
               <span>待结算金额：{{searchData.waitSettleAmount}}元</span>
             </div>
          </template>

          <template v-slot:table="row">
            <el-table
                  :data="tableData"
                  :height="row.heightText"
                  v-if="row.height">
                  <el-table-column
                    label="序号"
                    width="60">
                    <template slot-scope="scope">{{scope.$index + 1 + (currentPage - 1) * pageSize}}</template>
                  </el-table-column>
                  <el-table-column
                    prop="orderId"
                    label="订单号"
                    width="250">
                  </el-table-column>
                 <el-table-column
                    label="会员名称"
                    width="200">
                      <template slot-scope="scope">
                        <el-form>
                          {{scope.row.consumerJsonObj.nickName}}
                        </el-form>
                      </template>
                  </el-table-column>
                  <el-table-column
                    prop="phone"
                    label="手机号"
                    width="200">
                    <template slot-scope="scope">
                      <el-form>
                        {{scope.row.consumerJsonObj.phone}}
                      </el-form>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="amount"
                    label="佣金服务费金额(元)"
                    width="150">
                  </el-table-column>
                  <el-table-column
                      label="结算类型"
                      width="180">
                        <template slot-scope="scope">
                          {{statusMap[scope.row.status]}}
                        </template>
                  </el-table-column>

                  <el-table-column
                    prop="payTime_text"
                    label="支付时间"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="settleTime_text"
                    label="结算时间"
                    width="200">
                  </el-table-column>
                  <el-table-column label="操作" fixed="right" width="150">
                    <template slot-scope="scope">
                      <el-button size="mini" type="primary" @click="showAddNew(scope.row)">查看详情</el-button>
                    </template>
                  </el-table-column>
                </el-table>
          </template>

          <template slot="after">
            <div class="paging-row">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-sizes="[10, 15, 20]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="totalListNum">
                </el-pagination>
            </div>
          </template>

        </table-height-auto>

        <el-dialog :visible.sync="visibleAddNew"
                   title="佣金服务费详情"
                   center
                   width="60%">
          <el-form
            ref="drawerNameViewInfo">
              <el-row type="flex" class="row-bg" justify="space-between">
                <el-col :span="6">
                  <div class="grid-content bg-purple">
                    <el-form-item label="会员ID:">{{detailData.consumerUserId}}</el-form-item>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple">
                    <el-form-item label="会员名称:">{{detailData.consumerUserName}}</el-form-item>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple">
                    <el-form-item label="手机号:">{{detailData.consumerUserPhone}}</el-form-item>
                  </div>
                </el-col>
              </el-row>

              <el-row :gutter="20">
                <el-col :span="6">
                  <div class="grid-content bg-purple">
                    <el-form>
                       <el-form-item label="店铺ID:">{{detailData.supplierId}}</el-form-item>
                    </el-form>
                  </div>
                </el-col>
                <el-col :span="6" :offset="6">
                  <div class="grid-content bg-purple">
                    <el-form>
                       <el-form-item label="店铺名称:">{{detailData.supplierName}}</el-form-item>
                    </el-form>
                  </div>
                </el-col>
              </el-row>

              <el-row :gutter="20">
                <el-col :span="6">
                  <div class="grid-content bg-purple">
                    <el-form>
                       <el-form-item label="商品名称:">{{detailData.goodsName}}</el-form-item>
                    </el-form>
                  </div>
                </el-col>
                <el-col :span="6" :offset="6">
                  <div class="grid-content bg-purple">
                    <el-form>
                       <el-form-item label="商品数量:">{{detailData.num}}</el-form-item>
                    </el-form>
                  </div>
                </el-col>
              </el-row>

              <el-row :gutter="20">
                <el-col :span="6">
                  <div class="grid-content bg-purple">
                    <el-form>
                       <el-form-item label="结算状态:">{{detailData.status == 1 ? '待支付':detailData.status == 2 ? '待结算' :detailData.status == 3 ? '已结算' :detailData.status == 4 ? '已取消' :(detailData.status == 5 ? '已退款':'')}}

                       </el-form-item>
                    </el-form>
                  </div>
                </el-col>
                <el-col :span="6" :offset="6">
                  <div class="grid-content bg-purple">
                    <el-form>
                       <el-form-item label="订单状态:">
                          {{detailData.orderStatus == 1 ? '待支付':detailData.orderStatus == 2 ? '待发货' :detailData.orderStatus == 3 ? '待收货' :detailData.orderStatus == 4 ? '确认收货' :detailData.orderStatus == 5 ? '已完成' :detailData.orderStatus == 6 ? '已取消' :(detailData.orderStatus == 7 ? '交易关闭':'')}}
                       </el-form-item>
                    </el-form>
                  </div>
                </el-col>
              </el-row>

              <el-row type="flex" class="row-bg" justify="space-between">
                <el-col :span="6">
                  <div class="grid-content bg-purple">
                    <el-form-item label="售价:">{{detailData.price}}</el-form-item>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple">
                    <el-form-item label="让利率:"></el-form-item>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple">
                    <el-form-item label="佣金金额:" style="color: red;">{{detailData.amount}}</el-form-item>
                  </div>
                </el-col>
              </el-row>

              <el-row :gutter="20">
                <el-col :span="6">
                  <div class="grid-content bg-purple">
                    <el-form>
                       <el-form-item label="支付时间:">{{parseTime(detailData.payTime)}}</el-form-item>
                    </el-form>
                  </div>
                </el-col>
                <el-col :span="6" :offset="6">
                  <div class="grid-content bg-purple">
                    <el-form>
                       <el-form-item label="结算时间:">{{parseTime(detailData.settleTime)}}</el-form-item>
                    </el-form>
                  </div>
                </el-col>
              </el-row>

          </el-form>
          <span slot="footer" class="dialog-footer">
             <el-button type="primary" @click="handleSave">确 定</el-button>
          </span>

        </el-dialog>

  </div>
</template>

<script>
  import {URL} from '../../../config'
  import {disposeSearchData, getNextDayTimestamp, isEmpty, parseTime, parseTimeArray} from '../../../assets/js/utils'
  import {plusDownloadExcelTask} from '../../../store/excel'

  export default{
    name: 'adminFinanceCommission',
    components: {

    },
    data(){
      return {
        pickerOptions: {
          shortcuts: [
            {
              text: "今天",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24);
                picker.$emit("pick", [start, end]);
              }
            },
            {
              text: "近七天",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit("pick", [start, end]);
              }
            },
            {
              text: "最近一个月",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                picker.$emit("pick", [start, end]);
              }
            }
          ]
        },
        showStartEndTime: null,
        loading:false,
        tableData:[],
        searchData:{
          searchType:'consumerUserName',
          searchValue:'',
          selectTime:[],
          timeType:'1',
          type:'0',
          status:'0',
          supplierType:0,
          searchData:'',
          settledAmount:0,
          waitSettleAmount:0,
        },
        dialogVisible:false,
        visibleAddNew:false,
        searchKeyType:[
          {id: 'consumerUserName', name: '用户名'},
          {id: 'orderId', name: '订单号'},
        ],
        statusMap:{
          0:'全部',
          1:'待支付',
          2:'待结算',
          3:'已结算',
          4:'已取消',
          5:'已退款',
        },
        statusArray:[
          {id: '0', name: '全部'},
          // {id: '1', name: '待支付'},
          {id: '2', name: '待结算'},
          {id: '3', name: '已结算'},
          // {id: '4', name: '已取消'},
          {id: '5', name: '已退款'},
        ],
        timeTypeArray: [
          {
            id: "1",
            name: "支付时间"
          },
          {
            id: "2",
            name: "结算时间"
          }
        ],
        /* 分页参数 */
        pageSize:10,
        totalListNum:10,
        currentPage: 1,
        detailData:{},
      }
    },
    created() {
      let endText = new Date();
      let startText = new Date();
      startText.setTime(startText.getTime() - 3600 * 1000 * 24 * 30);
      this.searchData.selectTime = [parseTime(startText),parseTime(endText)];
      this.showStartEndTime = this.searchData.selectTime;
      this.getList();
      this.getStatistics();
    },

    methods:{
      parseTime,
      /**
       * 拼接搜索参数
       */
      jointSearchData(){
        let data = {
          pageNo:this.currentPage,
          pageSize:this.pageSize,
        };
        data = Object.assign(data,this.searchData);
        disposeSearchData(data);
        let selectTime = data.selectTime;
        let startTime = 0;
        let endTime = 0;

        if(!isEmpty(selectTime)){
          let times = selectTime.map(res=>new Date(res).getTime())
          startTime = times[0];
          endTime = getNextDayTimestamp(times[1]);
        }

        data.startTime = startTime;
        data.endTime = endTime;

        delete data.selectTime;
        return data;
      },
      /**
       * 搜索
       */
      search(){
        this.currentPage = 1;
        this.getList();
        this.getStatistics();
        this.showStartEndTime = this.searchData.selectTime;
      },
      getList(){
        return new Promise((resolve,reject) => {
          let data = this.jointSearchData();
          this.loading = true;
          // data.commissionUserType = 2;
          this.axios.get(URL.suppCommission.getSupplierCommissionList,{params: data}).then(res=>{
            let list = res.data.records || [];

            list.forEach(res=>{
               res.consumerJsonObj = {}
               if(res.consumerJson){
                 try{
                   res.consumerJsonObj = JSON.parse(res.consumerJson);
                 }catch(e){

                 }
                }
             });
            parseTimeArray(list,['payTime'],'YYYY-MM-DD HH：mm：ss')
            parseTimeArray(list,['settleTime'],'YYYY-MM-DD HH：mm：ss')
             this.tableData = list;
             this.totalListNum = res.data.total;
             resolve(res);
          }).catch(res=>{
            this.tableData = [];
            }).finally(res=>{
              this.loading = false;
            })
          })
        },

      getStatistics(){
        return new Promise((resolve,reject) => {
            let data = this.jointSearchData();
            this.loading = true;
            // data.commissionUserType = 2;
            this.axios.get(URL.suppCommission.getSupplierCommissionsSum, {params: data}).then(res=>{
              // let list = res.data || [];
              this.searchData.waitSettleAmount = res.data.waitSettleAmount;
              this.searchData.settledAmount = res.data.settledAmount;
               resolve(res);
            }).catch(res=>{
              this.tableData = [];
              }).finally(res=>{
                this.loading = false;
              })
            })
        },

        /**
         * 显示 编辑属性组弹窗
         * @param groups  {object}  //编辑时需要传入编辑的属性组对象
         * */
        showAddNew(row){
          this.loading = true;
          this.visibleAddNew = true
          this.dialogVisible = true
          let data= {
            id:row.id,
          }
          this.axios.get(URL.suppCommission.getCommissionsDetail, {params: data}).then(res=>{
            if(res.data){
              this.detailData = res.data;
            }
            parseTimeArray(list,['payTime'],'YYYY-MM-DD HH.mm.ss')
          }).catch(res=>{
            }).finally(res=>{
              this.loading = false;
            })
        },
        hideAddNew(){
          if(this.$refs.drawerNameViewInfo) this.$refs.drawerNameViewInfo()
          if(this.visibleAddNew) this.visibleAddNew = false;
        },
        handleSave(){
          this.visibleAddNew = false;
        },

      /* 分页 */
      handleSizeChange(val){
        this.pageSize = val;
        this.currentPage = 1;
        this.getList()
      },
      handleCurrentChange(val){
        this.currentPage = val;
        this.getList();
      },
      /**
       * 导出excel
       */
      downloadExcel(){
        let searchData = this.jointSearchData();
        let data = {
          name:'供应商佣金服务费明细报表',
          params:{
            excelType:1006,
            excelParameterJson:JSON.stringify(searchData),
          },
        };
        plusDownloadExcelTask.call(this,data);
      },
    }
  }

</script>

<style>
</style>
